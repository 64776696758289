<template>
  <div
    v-if="!isWebview() || isMobileApp()"
    class="bg-white text-black dark:text-white dark:bg-primary-1000 min-h-svh"
  >
    <InstallPrompt />

    <FrontBanner />
    <Transition v-bind="fade" mode="out-in">
      <HelpWidget
        v-if="!helpExeption.includes($route.name) && renderHelpWidget"
      />
    </Transition>
    <div class="sticky top-0 z-[60]">
      <LoggedInAsUser />
      <UIHeader />
    </div>
    <ModalsContainer />
    <router-view />
  </div>
  <div v-else class="flex flex-col h-screen items-center justify-center">
    <img class="w-64 h-64 mb-3" src="/images/mascot.svg" alt="sad bear" />

    <UIHeading
      title="Приложение больше не работает"
      tag="1"
      styles="3"
      class="mb-4 text-center"
    />
    <p class="max-w-[320px] text-center text-gray-600">
      К сожалению, это приложение больше не поддерживается и его можно удалить.
      Пожалуйста, используйте веб-версию портала nashsever51.ru и следите за
      обновлениями.
    </p>
  </div>
</template>

<script setup>
import { useTimeout } from '@vueuse/core'
import { inject, watch } from 'vue'
import { ModalsContainer } from 'vue-final-modal'
import { useRoute, useRouter } from 'vue-router'

import { useAuthStore } from '@/store/auth.js'
import { useGlobalStore } from '@/store/global.js'

import FrontBanner from '@/components/common/FrontBanner.vue'
import HelpWidget from '@/components/common/HelpWidget.vue'
import LoggedInAsUser from '@/components/common/LoggedInAsUser.vue'
import InstallPrompt from '@/components/pwa/InstallPrompt.vue'
import UIHeader from '@/components/ui/UIHeader.vue'
import UIHeading from '@/components/ui/UIHeading.vue'
import useEcho from '@/plugins/echo.js'
import { isMobileApp, isWebview } from '@/utils/global.js'
import { fade } from '@/utils/transitions.js'

const global = useGlobalStore()
const auth = useAuthStore()
const route = useRoute()
const router = useRouter()
const helpExeption = ['platform', 'platform.offer', 'onp']
const hooks = inject('hooks')

const theme = inject('theme')

if (isWebview() && !isMobileApp()) {
  theme.value = 'light'
}

let echo = null

const renderHelpWidget = useTimeout(3000)

watch(
  () => auth.token,
  () => {
    if (!auth.token && route.meta.auth) {
      router.push('/')
    }
  },
  {
    immediate: true,
  },
)

watch(
  () => auth.user,
  (value, oldValue) => {
    setTimeout(() => {
      if (auth.user) {
        echo = useEcho()
        echo
          .private(`notifications.${auth.user.id}`)
          .listen('.complaints.ping', ({ complaint, ping }) => {
            auth.user.notifications_count++
            hooks.complaintPing.trigger({ complaint, ping })
          })
      } else if (oldValue) {
        echo.leaveChannel(`notifications.${oldValue.id}`)
        echo = null
      }
    }, 50)
  },
  {
    immediate: true,
  },
)
</script>
