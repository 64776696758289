<template>
  <transition mode="out-in" @after-enter="afterEnter">
    <div
      v-if="loading"
      class="flex min-h-[200px] w-full items-center justify-center"
    >
      <LoadingIcon class="text-primary h-10 w-10" :class="loadingClasses" />
    </div>
    <div
      v-else-if="error"
      class="w-full flex flex-col items-center justify-center"
    >
      <slot name="error">
        <UIHeading
          class="mb-3"
          :title="errors[error.response.status].title || 'Что-то пошло не так'"
          tag="1"
        />
        <p
          class="mb-4"
          v-text="
            errors[error.response.status].caption ||
            'Похоже, что мы все потерялись на этой странице.'
          "
        />
        <RouterLink
          class="text-primary"
          to="/"
          v-text="'Вернемся на главную?'"
        />
      </slot>
    </div>
    <div v-else>
      <slot />
    </div>
  </transition>
</template>

<script setup>
import UIHeading from '@/components/ui/UIHeading.vue'

import LoadingIcon from './LoadingIcon.vue'

defineProps({
  loading: {
    type: Boolean,
    required: true,
  },
  afterEnter: Function,
  loadingClasses: String,
  error: {
    type: Object,
    default: null,
  },
})

const errors = {
  404: {
    title: 'Ой-ой! Тут ничего нет!',
    caption: 'Похоже, что мы все потерялись на этой странице.',
  },
}
</script>
