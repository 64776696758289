<template>
  <div
    class="flex relative flex-col dark:text-white m-auto py-8 max-w-[402px] duration-1000 w-full"
    :class="context.wrapperClass"
  >
    <button class="max-md:hidden" @click="context.close">
      <IconX
        class="absolute size-4 right-4 md:-right-2 -top-2 translate-x-full text-white -translate-y-full size-6"
      />
    </button>

    <Transition v-bind="fade" mode="out-in">
      <div
        v-if="auth.loading.login || auth.loading.register"
        class="absolute inset-0 bg-black-800/30 rounded-lg z-50"
      />
    </Transition>

    <div
      class="transition-[height] overflow-hidden py-px px-[2px] relative"
      :style="`height: ${containerHeight};`"
    >
      <Transition
        v-bind="activeTransition"
        mode="out-in"
        @before-leave="setHeightLeave"
        @before-enter="setHeightEnter"
      >
        <div v-if="step === 0">
          <!--Heading-->
          <div class="flex items-center justify-between mb-8">
            <UIHeading title="Вход" tag="2" />
            <button
              class="text-primary dark:text-white flex items-center group transition-colors hover:text-primary-800"
              @click="goTo(1)"
            >
              <span>Регистрация</span>
              <IconChevronRight
                class="w-0 group-hover:w-4 transition-all mt-0.5"
              />
            </button>
          </div>
          <!--Form-->
          <FormKit id="form-login" v-model="login" type="form" :actions="false">
            <FormKit
              name="login"
              type="text"
              autocomplete="username"
              placeholder="Электронная почта"
              @keydown.enter="(e) => e.preventDefault()"
            />
            <FormKit
              name="password"
              autocomplete="current-password"
              :type="passwordInputType"
              placeholder="Пароль"
              @keydown.enter="(e) => e.preventDefault()"
            >
              <template #suffix="context">
                <button
                  tabindex="-1"
                  class="px-3 text-black-400 transition hover:text-black-900"
                  @click.stop.prevent="switchEye(context)"
                >
                  <IconEye v-if="context.type === 'password'" class="h-5 w-5" />
                  <IconEyeOff v-else class="h-5 w-5" />
                </button>
              </template>
            </FormKit>
          </FormKit>
          <div class="flex justify-between items-center mb-12">
            <FormKit
              v-model="login.remember"
              type="checkbox"
              :classes="{ wrapper: '!mb-0', outer: '!mb-0' }"
              label="Запомнить меня"
            />
            <button
              @click="goTo(2)"
              class="text-base text-primary dark:text-white transition-colors hover:text-primary-800"
            >
              Восстановить пароль
            </button>
          </div>

          <UIButton
            class="w-full mb-5 rounded-md"
            title="Войти"
            :loading-icon="Loading"
            :is-loading="auth.loading.login"
            size="lg"
            @click="userLogin"
          />

          <p
            class="mx-auto text-black-600 dark:text-black-300 text-center mb-4"
          >
            Войти другим способом
          </p>

          <button
            @click="openEsiaModal"
            class="py-2 min-h-[42px] w-full ring-1 hover:bg-primary-50 transition-colors ring-primary-600 flex items-center bg-white justify-center text-primary-600 rounded-md px-4"
          >
            <img
              class="flex-none mr-2"
              src="/images/socials/gosuslugi.svg"
              alt="gosuslugi"
              draggable="false"
            />
            <span>Войти через Госуслуги</span>
          </button>
        </div>

        <div v-else-if="step === 1">
          <!--Heading-->
          <div class="flex items-center justify-between mb-8">
            <UIHeading title="Регистрация" tag="2" />
            <button
              class="text-primary dark:text-white flex items-center group transition-colors hover:text-primary-800"
              @click="goTo(0)"
            >
              <IconChevronRight
                class="w-0 group-hover:w-4 transition-all rotate-180 mt-0.5"
              />
              Вход
            </button>
          </div>
          <!--Form-->
          <FormKit
            id="form-register"
            v-model="register"
            type="form"
            :classes="{ form: 'mb-12' }"
            :actions="false"
          >
            <FormKit
              name="fio"
              type="fio"
              placeholder="ФИО"
              validation="language_exclusion"
              validation-visibility="live"
              :validation-rules="{ language_exclusion }"
              :validation-messages="{
                language_exclusion: 'Пожалуйста используйте кириллицу',
              }"
              @keydown.enter="(e) => e.preventDefault()"
            />
            <FormKit
              name="email"
              type="text"
              placeholder="Электронная почта"
              @keydown.enter="(e) => e.preventDefault()"
            />
            <div class="grid grid-cols-[1fr_160px] gap-4 mb-3">
              <FormKit
                :classes="{ outer: '!mb-0' }"
                name="code"
                type="text"
                placeholder="Код подтверждения"
                @keydown.enter="(e) => e.preventDefault()"
              />
              <UIButton
                :title="isCountDown ? `${countDown}` : 'Прислать код'"
                class="mb-1 rounded-md"
                type="secondary"
                icon-classes="w-5 h-5"
                :loading-icon="Loading"
                :icon="isCountDown ? IconClockPause : () => {}"
                :disabled="!register.email || isCountDown || !isEmailValid"
                :is-loading="auth.loading.code"
                @click="sendCode"
              />
            </div>
            <FormKit
              name="phone"
              type="phone"
              placeholder="Мобильный телефон"
              @keydown.enter="(e) => e.preventDefault()"
            />
            <FormKit
              name="password"
              :type="passwordInputType"
              autocomplete="autocomplete"
              placeholder="Пароль"
              @keydown.enter="(e) => e.preventDefault()"
            >
              <template #suffix="context">
                <button
                  tabindex="-1"
                  class="px-3 text-black-400 transition hover:text-black-900"
                  @click.stop.prevent="switchEye(context)"
                >
                  <IconEye v-if="context.type === 'password'" class="h-5 w-5" />
                  <IconEyeOff v-else class="h-5 w-5" />
                </button>
              </template>
            </FormKit>
            <div class="mt-8">
              <FormKit
                :classes="{ outer: '!mb-0' }"
                name="policy"
                type="checkbox"
              >
                <template #label>
                  <span class="text-base leading-5 cursor-pointer">
                    Я даю согласие на обработку
                    <a
                      href="/policy"
                      target="_blank"
                      class="underline underline-offset-4 transition-colors hover:text-primary-800"
                      >персональных данных</a
                    >
                  </span>
                </template>
              </FormKit>
              <FormKit
                :classes="{ outer: '!mb-0' }"
                name="notification"
                type="checkbox"
                label="Я даю согласие на получение важных уведомлений"
              />
            </div>
          </FormKit>
          <UIButton
            title="Зарегистрироваться"
            size="xl"
            class="w-full rounded-md"
            :loading-icon="Loading"
            :is-loading="auth.loading.register"
            :disabled="!(isCodValid && register.policy)"
            @click="userRegister"
          />
        </div>

        <div v-else-if="step === 2">
          <!--Heading-->
          <div class="flex items-start justify-between gap-3 mb-8">
            <UIHeading title="Восстановление пароля" tag="2" />
            <button
              class="text-primary dark:text-white flex items-center group transition-colors hover:text-primary-800 mt-1"
              @click="goTo(0)"
            >
              <IconChevronRight
                class="w-0 group-hover:w-4 transition-all rotate-180 mt-0.5"
              />
              Вход
            </button>
          </div>

          <p class="text-sm mb-4 text-black/70 dark:text-white/70">
            Укажите вашу почту для восстановления пароля. На указанный адрес
            придет письмо со ссылкой для авторизации в личном кабинете.
          </p>

          <!--Form-->
          <FormKit
            id="form-restore"
            v-model="restore"
            type="form"
            :classes="{ form: 'mb-12' }"
            :actions="false"
          >
            <FormKit
              name="email"
              type="text"
              placeholder="example@mail.ru"
              @keydown.enter="(e) => e.preventDefault()"
            />
          </FormKit>
          <UIButton
            title="Восстановить"
            size="xl"
            class="w-full rounded-md"
            :loading-icon="Loading"
            :is-loading="auth.loading.restore"
            @click="userRestore"
          />
        </div>
      </Transition>
    </div>
  </div>
</template>

<script setup>
import { clearErrors, setErrors } from '@formkit/vue'
import { notify } from 'notiwind'
import { computed, nextTick, ref, watch } from 'vue'

import { useAuthStore } from '@/store/auth.js'

import {
  IconChevronRight,
  IconClockPause,
  IconEye,
  IconEyeOff,
  IconX,
} from '@tabler/icons-vue'

import Loading from '@/components/common/Loading.vue'
import EsiaErrorModal from '@/components/modals/EsiaErrorModal.vue'
import UIButton from '@/components/ui/UIButton.vue'
import UIHeading from '@/components/ui/UIHeading.vue'
import useModalDynamic from '@/plugins/useModalDynamic.js'
import { isMobileApp } from '@/utils/global.js'
import createSocialAuthWindow from '@/utils/social.js'
import { fade, fadeSlideLeft, fadeSlideRight } from '@/utils/transitions.js'

const props = defineProps({
  context: {
    type: Object,
    default: {},
  },
})
const auth = useAuthStore()
const step = ref(0)
const login = ref({})
const register = ref({
  policy: true,
  notification: true,
})
const checker = ref({ agree: [] })
const containerHeight = ref('auto')
const countDown = ref(60)
const isCountDown = ref(false)
const isCodValid = ref(false)
const isEmailValid = ref(false)

const isLoading = ref({
  auth: false,
  register: false,
  restore: false,
})

const restore = ref({
  email: null,
})

const socials = [
  {
    label: 'Yandex',
    social: 'yandex',
    img: '/images/socials/yandex.svg',
  },
  {
    label: 'Mail',
    social: 'mail',
    img: '/images/socials/mail.svg',
  },
  {
    label: 'Odnoklassniki',
    social: 'ok',
    img: '/images/socials/odnoklassniki.svg',
  },
]

const passwordInputType = ref('password')

const activeTransition = computed(() => {
  return step.value > 0 ? fadeSlideLeft : fadeSlideRight
})

// Rule for fio field
function language_exclusion({ value }) {
  if (!value.raw) return
  const pattern = /^[а-яА-Я\s]*$/
  return pattern.test(value.raw)
}

// For modal height animation
function setHeightLeave(el) {
  containerHeight.value = el.getBoundingClientRect().height + 'px'
}

function setHeightEnter(el) {
  nextTick(() => {
    containerHeight.value = el.getBoundingClientRect().height + 'px'
    setTimeout(() => {
      containerHeight.value = 'auto'
    }, 155)
  })
}

function switchEye({ node }) {
  passwordInputType.value = node.props.type === 'text' ? 'password' : 'text'
}

function userLogin() {
  auth
    .login(login.value)
    .then(() => props.context.close())
    .catch((e) => {
      setErrors('form-login', e.data.errors?.global ?? [], e.data.errors)
    })
}

function userRegister() {
  clearErrors('form-register')
  let data = { ...register.value, ...register.value.fio }
  delete data.raw
  delete data.fio

  auth
    .register(data)
    .then(({ token }) => {
      auth.loginWithToken(token)
      props.context.close()
    })
    .catch((e) => {
      setErrors('form-register', e.data.errors?.global ?? [], e.data.errors)
    })
}

function userRestore() {
  clearErrors('form-restore')

  auth
    .restore(restore.value)
    .then(() => {
      notify(
        {
          group: 'default',
          type: 'success',
          title: 'Восстановление пароля',
          text: 'Ссылка для авторизации и последующей смены пароля отправлена.',
        },
        3000,
      )
      props.context.close()
    })
    .catch((e) => {
      setErrors('form-restore', e.data.errors?.global ?? [], e.data.errors)
    })
}

watch(
  () => register.value.email,
  () => {
    checkEmail()
  },
)

watch(
  () => register.value.code,
  () => {
    isCodValid.value = register.value.code.length === 6
  },
)

function checkEmail() {
  register.value.email = register.value.email.replace(/ /g, '')
  const pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
  isEmailValid.value = pattern.test(register.value.email)
}

function countDownTimer() {
  if (countDown.value > 0) {
    setTimeout(() => {
      countDown.value -= 1
      countDownTimer()
    }, 1000)
  } else {
    isCountDown.value = false
  }
}

async function sendCode() {
  await auth
    .sendCode(register.value.email)
    .then(() => {
      countDown.value = 60
      isCountDown.value = true
      countDownTimer()
    })
    .catch((e) => {
      setErrors('form-register', e.data.errors)
    })
}

function goTo(val) {
  step.value = val
  passwordInputType.value = 'password'
}

function createWindow(social) {
  createSocialAuthWindow(social, async (event) => {
    if (event) {
      if (event.data.event === 'auth') {
        auth
          .loginViaSocial(event.data.query)
          .then(async ({ token }) => {
            await auth.loginWithToken(token, true)
            props.context.close()
          })
          .catch((err) => {
            if (err && err.statusCode === 500) {
              useModalDynamic({
                opened: true,
                component: EsiaErrorModal,
              })
            }
          })
      }
    }
  })
}

function openEsiaModal() {
  if (isMobileApp()) {
    window.location.href = `/api/auth/socials/esia`
  } else {
    createWindow('esia')
  }
}
</script>
